export default {
  borderWidth: 0,
  padding: 10,
  style: {
    color: "#fff",
    cursor: "default",
    fontSize: "14px",
    pointerEvents: "none",
    whiteSpace: "nowrap",
  },
  shadow: false,
  shared: true,
  crosshairs: true,
  headerFormat:
    '<div style="color: #fff; font-size: 14px; padding-bottom: 5px; font-weight: bold;">{point.key}</div>',
  useHTML: true,
  formatter: undefined,
};
